import { render, staticRenderFns } from "./wave.vue?vue&type=template&id=b8f13abc&scoped=true&"
import script from "./wave.vue?vue&type=script&lang=js&"
export * from "./wave.vue?vue&type=script&lang=js&"
import style0 from "./wave.vue?vue&type=style&index=0&id=b8f13abc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8f13abc",
  null
  
)

export default component.exports