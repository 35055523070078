var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"nav",class:{
    'color-dark': !_vm.isWhite && !_vm.isNavShown && !_vm.isMenuShown,
    'nav-shown': _vm.isNavShown,
    'ja': _vm.$i18n.locale == 'ja'
  }},[_c('div',{staticClass:"nav-controls"},[_c('div',{staticClass:"logo-wrapper"},[_c('router-link',{staticClass:"logo",attrs:{"to":"/"}})],1),_c('div',{staticClass:"control-container",on:{"click":_vm.onMenuOpen}},[(!_vm.isMenuShown)?_c('span',{staticClass:"control-title"},[_vm._v("Menu")]):_vm._e(),(_vm.isMenuShown)?_c('span',{staticClass:"control-title"},[_vm._v("Close")]):_vm._e(),_c('div',{staticClass:"hamburger",class:{
          'on-close': _vm.isMenuShown === false,
          'on-open': _vm.isMenuShown === true,
        }},[_c('div',{staticClass:"inner"})])])]),_c('div',{staticClass:"nav-body",class:{
      'body-shown': _vm.isMenuShown
    },attrs:{"id":"menu-body"}},[_c('div',{staticClass:"menu-items"},[_vm._m(0),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/company"}},[_c('Wave',{attrs:{"text":_vm.$t('navigation.about'),"isLight":true}})],1)],1),_c('li',[_c('router-link',{attrs:{"to":"/services"}},[_c('Wave',{attrs:{"text":_vm.$t('navigation.services'),"isLight":true}})],1)],1),_c('li',[_c('router-link',{attrs:{"to":"/works"}},[_c('Wave',{attrs:{"text":_vm.$t('navigation.works'),"isLight":true}})],1)],1),_c('li',[_c('router-link',{attrs:{"to":"/contacts"}},[_c('Wave',{attrs:{"text":_vm.$t('navigation.contactUs'),"isLight":true}})],1)],1)])]),_c('div',{staticClass:"action-items"},[_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t('navigation.text1')))]),_c('span',[_vm._v(_vm._s(_vm.$t('navigation.text2')))]),_c('span',[_vm._v(_vm._s(_vm.$t('navigation.text3')))])]),_c('router-link',{staticClass:"action-link",attrs:{"to":"/youtube"}},[_c('span',[_vm._v(_vm._s(_vm.$t('navigation.youtubePromo')))]),_c('svg',{attrs:{"width":"44","height":"25","viewBox":"0 0 44 25","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M38.5 11l-8-8L33 .5 44 12v1L33 24.5 30.5 22l8-8H0v-3h38.5z","fill":"#fff","fill-rule":"nonzero"}})])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"line"},[_c('span',[_vm._v("Menu")])])}]

export { render, staticRenderFns }