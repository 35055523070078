<template>
  <nav
    class="nav"
    :class="{
      'color-dark': !isWhite && !isNavShown && !isMenuShown,
      'nav-shown': isNavShown,
      'ja': $i18n.locale == 'ja'
    }"
  >
    <div class="nav-controls">
      <div class="logo-wrapper">
        <router-link class="logo" to="/"></router-link>
      </div>
      <div
        v-on:click="onMenuOpen"
        class="control-container"
      >
        <span class="control-title" v-if="!isMenuShown">Menu</span>
        <span class="control-title" v-if="isMenuShown">Close</span>
        <div
          :class="{
            'on-close': isMenuShown === false,
            'on-open': isMenuShown === true,
          }"
          class="hamburger"
        >
          <div class="inner"></div>
        </div>
      </div>
    </div>
    <div id="menu-body" class="nav-body" :class="{
        'body-shown': isMenuShown
      }">
      <div class="menu-items">
        <div class="line">
          <span>Menu</span>
        </div>
        <ul>
          <li>
            <router-link to="/company">
              <Wave
              :text="$t('navigation.about')"
              :isLight="true"
              />
            </router-link></li>
          <li>
            <router-link to="/services">
              <Wave
                :text="$t('navigation.services')"
                :isLight="true"
              />
            </router-link>
          </li>
          <li>
            <router-link to="/works">
              <Wave
                :text="$t('navigation.works')"
                :isLight="true"
              />
            </router-link>
          </li>
          <li>
            <router-link to="/contacts">
              <Wave
                :text="$t('navigation.contactUs')"
                :isLight="true"
              />
            </router-link>
          </li>
        </ul>
      </div>
      <div class="action-items">
        <p>
          <span>{{ $t('navigation.text1') }}</span>
          <span>{{ $t('navigation.text2') }}</span>
          <span>{{ $t('navigation.text3') }}</span>
        </p>
        <router-link class="action-link" to="/youtube">
          <span>{{ $t('navigation.youtubePromo') }}</span>
          <svg width="44" height="25" viewBox="0 0 44 25" xmlns="http://www.w3.org/2000/svg"><path d="M38.5 11l-8-8L33 .5 44 12v1L33 24.5 30.5 22l8-8H0v-3h38.5z" fill="#fff" fill-rule="nonzero"></path></svg>
        </router-link>
      </div>
    </div>
  </nav>
</template>
<script>
import Wave from "./wave";

export default {
  data() {
    return {
      isMenuShown: false,
      isColorDark: false,
      isNavShown: false,
      windowPosition: 0,
      mobileWidth: 793,
      tabletWidth: 992,
    };
  },
  components: {
    Wave
  },
  props: {
    isWhite: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    onMenuOpen() {
      let bottomPanel = document.querySelector(".bottom-panel");
      this.isMenuShown = !this.isMenuShown;

      if(this.isMenuShown) {
        window.FB.CustomerChat.hide();
      } else {
         window.FB.CustomerChat.show(false);
      }

      this.onScrollEvent();

      if(this.isMenuShown) {
        document.body.style.overflowY = "hidden";
        document.getElementById('menu-body').style.display = "flex";
        bottomPanel.style.zIndex = 10;
      } else {
        document.body.style.overflowY = "auto";
        setTimeout(() => document.getElementById('menu-body').style.display = "none", 500);
        bottomPanel.style.zIndex = 12;
      }
    },
    onScrollEvent() {

      if(this.isMenuShown) {
        this.isColorDark = false;
        this.isNavShown = false;
        return;
      }

      if (this.isMobile() || this.isTablet()) {
        this.isColorDark = false;
        this.isNavShown = true;
        return;
      }
      
      let wPos = document.documentElement.scrollTop || document.body.scrollTop;

      let isScrollingUp = wPos < this.windowPosition;
      let isScrolledToTop =  wPos != 0;
      let isScrollerdToBottom = (window.innerHeight + wPos + 2) >= document.body.offsetHeight;
      
      this.isNavShown = (isScrollingUp && isScrolledToTop) || isScrollerdToBottom;

      this.windowPosition = wPos;
    },
    isMobile() {
      return this.getWindowWidth() <= this.mobileWidth;
    },
    isTablet() {
      return (
        this.getWindowWidth() >= this.mobileWidth &&
        this.getWindowWidth() <= this.tabletWidth
      );
    },
    getWindowWidth() {
      return (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      );
    }
  },
  created() {
    window.addEventListener("scroll", this.onScrollEvent);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScrollEvent);
  },
  mounted() {
    document.body.style.overflowY = "auto";
    document.getElementById('menu-body').style.display = "none";
    this.onScrollEvent();
  },
};
</script>
<style lang="scss" scoped>
@import "../scss/app";
$hamburger-animation-duration: 0.6s;
$angleFrom: 0deg;
$angleTo: 135deg;

.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9998;
  height: 88px;
}

.nav-controls {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  text-align: left;
  z-index: 5;
  padding: 24px 34px;
  height: 88px;

  @include transition(all 0.5s ease);

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom:-3px;
    left: 0;
    right: 0;
    background: transparent;
    z-index: -1;
    @include transition(all 0.5s ease);
  }
}

.logo-wrapper {
  display: flex;
}

.logo {
  position: relative;
  top: -3px;
  display: block;
  background-image: url(/images/logo/D&D_h_logo_full_white.png);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 40px;
  width: 94px;
}

.control-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 35px;
}

.hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;

  .inner {
    position: relative;
    display: block;
    width: 24px;
    height: 12px;

    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      height: 3px;
      background-color: $white;
    }

    &::after {
      top: 0;
    }

    &::before {
      bottom: 0;
    }
  }

  &.on-close .inner {
    @include transition(narrow-twist-off $hamburger-animation-duration);
    @include animation(narrow-twist-off $hamburger-animation-duration);
    @include animation-fill-mode(both);
  }

  &.on-open .inner {
    @include transition(narrow-twist-on $hamburger-animation-duration);
    @include animation(narrow-twist-on $hamburger-animation-duration);
    @include animation-fill-mode(both);
  }
}

.control-title {
  position: relative;
  top: 6px;
  height: 35px;
  padding-right: 5px;
  font-family: sans-serif;
  @include font-size(16px);
  color: $white;
  text-transform: uppercase;
  font-weight: 600;
}

@include keyframes(narrow-twist-on) {
  30% {
    height: 3px;
    width: 30px;
    @include rotate($angleFrom);
  }
  100% {
    height: 3px;
    width: 30px;
    @include rotate($angleTo);
  }
}

@include keyframes(narrow-twist-off) {
  0% {
    height: 3px;
    width: 30px;
    @include rotate($angleTo);
  }
  30% {
    height: 12px;
    width: 24px;
    @include rotate($angleTo);
  }
  100% {
    @include rotate($angleFrom);
  }
}

.nav.nav-shown {
    .nav-controls::after {
        background: rgba(25, 26, 26, 0.92);
        backdrop-filter: blur(15px) brightness(150%) saturate(180%);
        bottom: 0px;
    }
}

.nav.color-dark {
    .logo {
        background-image: url(/images/logo/D&D_h_logo_full_black.png);
    }

    .hamburger .inner {
        &::after,
        &::before {
            background-color: #000;
        }
    }

    .control-title {
        color: #000;
    }
}

@media (max-width: 992px) {
  .nav {
    height: 60px;
    z-index: 9998;
  }

  .logo {
    top:-2px;
    height: 30px;
    width: 70px;
  }

  .nav-controls {
    padding: 0 14px;
    height: 60px;
  }

  .control-title {
    display: none;
  }
}

.nav-body {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  padding: 0 15% 0;
  background: rgba(25, 26, 26, .92);
  backdrop-filter: blur(15px) brightness(150%) saturate(180%);
  @include animation(nav-body-hide .5s ease);
  @include animation-fill-mode(both);
  @include transition(all .5s);

  &.body-shown {
    @include animation(nav-body-show .5s ease);
    @include animation-fill-mode(both);
    @include transition(all .5s);
  }
}

@include keyframes(nav-body-show) {
  0% {
    @include opacity(0);
    padding-bottom: 100px;
  }
  60% {
    @include opacity(1);
  }
  100% {
    @include opacity(1);
    padding-bottom: 0;
  }
}

@include keyframes(nav-body-hide) {
  0% {
    @include opacity(1);
    padding-bottom: 0;
  }
  60% {
    @include opacity(0);
  }
  80% {
    padding-bottom: 100px;
  }
  100% {
    @include opacity(0);
    padding-bottom: 100px;
    display: none;
  }
}

.menu-items {
  position: relative;
  width: 50%;
  padding-left: 70px;
  padding-right: 20px;

  a {
    display: inline-block;
    @include font-size(34px);
    font-weight: 400;
    margin-bottom: 28px;
    color: #fff;
    text-decoration: none;
  }

  .line {
    position: absolute  ;
    top: 43px;
    left: 0;
    bottom: 20px;
    border-left: 1px solid #fff;

    span {
      display: inline-block;
      @include rotate(90deg);
      position: relative;
      color:#fff;
      top: -43px;
      left: -20px;
    }
  }
}



.action-items {
  width: 50%;
  p {
    font-size: 40px;
    font-size: 2.5rem;
    font-size: 2.7vw;
    color: #fff;
    margin-bottom: 38px;
    span {
      display: block;
      line-height: 57px;
      white-space: nowrap;
    }
  }

  .action-link {
    position: relative;
    color: rgba(0, 0, 0, 1);
    @include font-size(24px);
    line-height: 28px;
    cursor: pointer;
    z-index: 0;
    padding: 13px 20px 13px;
    background-color: #fff;

    &::after {
      content: '';
      position: absolute;
      top:0;
      bottom:0;
      left: 0;
      right: 0;
      background: #fff;
      z-index: 1;
      background-color: #4923D8;
      @include opacity(0);
      @include transition(all 500ms);
    }

    svg {
      position: relative;
      width: 25px;
      top: -1.5px;
      margin-left: 12px;
      display: inline-block;
      z-index: 2;
      @include transition(all 500ms);

      path {
        fill: #000;
      }
    }

    &:hover {
      color: rgba(255, 255, 255, 1);
      &:after {
        @include opacity(1);
      }

      svg {
        margin-left: 32px;
        path {
          fill: #fff;
        }
      }
    }

    span {
        position: relative;
        display: inline-block;
        z-index: 2;
        @include font-size(24px);
        line-height: 24px;
        font-family: $family-medium;
    }
  }
}

.ja .action-items .action-link span {
  font-weight: 600;
  bottom: -2px;
}

@media (max-width: 992px) {
  .nav-body {
    flex-direction: column;
  }

  .menu-items {
    width: 100%;
    position: relative;
    top: -5vh;
  }

  .action-items {
    position: absolute;
    width: 100%;
    bottom: 0;
    p {
      span {
        display: none;
      }
    }

    .action-link {
      margin: auto;
      display: block;
      @include font-size(15px);
      width: 70%;
      bottom: 10vh;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      span {
            @include font-size(14px);
      }
    }
  }
}

</style>
