<template>
    <transition>
        <span class="wrapper">
            <span
                v-if="isLight"
                v-on:mouseover="onOver"
                v-on:mouseleave="onLeave" 
                class="light" 
                v-bind:class="{
                    notActive: isActive === false,
                    active: isActive === true,
                }">
                <span  v-for="(val, key) in text" v-bind:key="key">{{val}}</span>
            </span>
            <span
                v-if="!isLight"
                v-on:mouseover="onOver"
                v-on:mouseleave="onLeave" 
                class="dark" 
                v-bind:class="{
                    notActive: isActive === false,
                    active: isActive === true,
                }">
                <span  v-for="(val, key) in text" v-bind:key="key">{{val}}</span>
            </span>
        </span>
    </transition>
</template>

<script>
export default {
    data() {
        return {
            isActive: null,
            mobileWidth: 793,
            tabletWidth: 992,
        }
    },
    props: {
        text: String,
        isLight: Boolean
    },
    methods: {
        onOver() {
            if(!(this.$i18n.locale == 'ja' && (this.isMobile() || this.isTablet()))) {
                this.isActive = true;
            }
        },
        onLeave() {
            if(!(this.$i18n.locale == 'ja' && (this.isMobile() || this.isTablet()))) {
                this.isActive = false;
                setTimeout(() => { this.isActive = null }, 350);
            }
        },
        isMobile() {
            return this.getWindowWidth() <= this.mobileWidth;
        },
        isTablet() {
            return this.getWindowWidth() >= this.mobileWidth && this.getWindowWidth() <= this.tabletWidth;
        },
        getWindowWidth() {
            return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../scss/app';

$duration: .35s;
$charCoutn: 10;

$colorBlack:rgb(25,26,26);
$colorLight: rgb(255, 255, 255);
$colorMiddle: rgb(255, 217, 0);
$colorActive: rgb(73,35,216);


.wrapper, .dark, .light  {
    display: inline-block;
    position: relative;
}

.dark {
    color: $colorBlack;
    line-height: 1em;
}

.light {
    color: $colorLight;
    line-height: 1em;
}

.dark.notActive span {
    @include transition(color-backward $duration);
    @include animation(color-backward $duration);
    @include animation-fill-mode(both);

    @for $i from 1 through $charCoutn {
        &:nth-child( #{$charCoutn}n + #{$i} ) {
            @include animation-delay(($duration / $charCoutn) * ($charCoutn - $i));
        }
    }
}

.dark.active span {
    @include transition(color-forward $duration);
    @include animation(color-forward $duration);
    @include animation-fill-mode(both);

    @for $i from 1 through $charCoutn {
        &:nth-child( #{$charCoutn}n + #{$i} ) {
            @include animation-delay($duration / $charCoutn * $i);
        }
    }
}

@include keyframes(color-forward) {
    0% {
        color: $colorBlack;
    }
    10% {
        color: rgb(246, 189, 37);
    }
    20% {
        color: rgb(239, 161, 55);
    }
    30% {
        color: rgb(231, 133, 73);
    }
    40% {   
        color: rgb(222, 100, 94);
    }
    50% {
        color: rgb(214, 68, 114);
    }
    60% {
        color: rgb(180, 59, 139);
    }
    70% {
        color: rgb(144, 51, 165);
    }
    80% {
        color: rgb(73, 35, 216);
    }
    100% {
        color: $colorActive;
    }
}

@include keyframes(color-backward) {
    0% {
        color: $colorActive;
    }
    100% {
        color: $colorBlack;
    }
}

 
.light.notActive span {
    font-weight: normal;
    @include transition(color-light-backward $duration);
    @include animation(color-light-backward $duration);
    @include animation-fill-mode(both);

    @for $i from 1 through $charCoutn {
        &:nth-child( #{$charCoutn}n + #{$i} ) {
            @include animation-delay(($duration / $charCoutn) * ($charCoutn - $i));
        }
    }
}

.light.active span {
    @include transition(color-light-forward $duration);
    @include animation(color-light-forward $duration);
    @include animation-fill-mode(both);

    @for $i from 1 through $charCoutn {
        &:nth-child( #{$charCoutn}n + #{$i} ) {
            @include animation-delay($duration / $charCoutn * $i);
        }
    }
}


@include keyframes(color-light-forward) {
    0% {
        color: $colorLight;
    }
    10% {
        color: rgb(246, 189, 37);
    }
    20% {
        color: rgb(239, 161, 55);
    }
    30% {
        color: rgb(231, 133, 73);
    }
    40% {   
        color: rgb(222, 100, 94);
    }
    50% {
        color: rgb(214, 68, 114);
    }
    60% {
        color: rgb(180, 59, 139);
    }
    70% {
        color: rgb(144, 51, 165);
    }
    80% {
        color: rgb(73, 35, 216);
    }
    100% {
        color: $colorActive;
    }
}

@include keyframes(color-light-backward) {
    0% {
        color: $colorActive;
    }
    100% {
        color: $colorLight;
    }
}
</style>